import { Flex, Stack } from "@chakra-ui/react";
import { useAccount } from "wagmi";
import { useTranslation } from "next-i18next";
import { Text } from "@looksrare/uikit";
import { EnterCode } from "@/views/Onboarding/components/EnterCode";
import { ConnectWalletButton } from "@/components/ConnectWalletButton";

interface EnterCodeStepProps {
  setValidatedInput: (input: string) => void;
}
export const EnterCodeStep = ({ setValidatedInput }: EnterCodeStepProps) => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  return (
    <Stack px={{ base: 4, md: 12 }} spacing={9} alignItems="center" width={{ base: "360px", md: "auto" }}>
      <Text width="100%" maxWidth="383px" textStyle="display-03" bold color="text-01" textAlign="center">
        {t("Welcome to the Party")}
      </Text>
      <EnterCode onValidCode={setValidatedInput} />
      {!isConnected && (
        <Flex gap={2} alignItems="center">
          <Text textStyle="detail" color="text-03">
            {t("Already in?")}
          </Text>
          <ConnectWalletButton variant="ghost" size="xs" colorScheme="secondary">
            {t("Connect Wallet")}
          </ConnectWalletButton>
        </Flex>
      )}
    </Stack>
  );
};
