import { useCallback } from "react";
import { UseMutationOptions, useMutation, useQueryClient } from "@tanstack/react-query";
import { Address } from "viem";
import { useAccount } from "wagmi";
import { getAuthToken, graphql } from "@looksrare/utils";
import { gql } from "graphql-request";
import { SuccessPayload, User } from "@/types";

export const userKeys = {
  user: (address: Address) => ["user", "single", address],
};

export const useInvalidateUser = () => {
  const queryClient = useQueryClient();

  return useCallback(
    (address: Address) => {
      queryClient.invalidateQueries({ queryKey: userKeys.user(address) });
    },
    [queryClient]
  );
};

export type UserUpdateInput = {
  biography?: User["biography"] | null;
  name?: User["name"] | null;
};

export interface UpdateUserParams {
  address: Address;
  data: UserUpdateInput;
}

interface UsernameValidityResponse {
  usernameValid: boolean;
}

/**
 * Check if a username is valid query
 */
export const checkUsernameValidity = async (username: string): Promise<boolean> => {
  const query = gql`
    query CheckUsernameValidity($username: String!) {
      usernameValid(username: $username)
    }
  `;

  const res: UsernameValidityResponse = await graphql({ query, params: { username } });
  return res.usernameValid;
};

/**
 * Update user profile mutation
 */
export const updateUser = async (address: Address, userData: UserUpdateInput): Promise<SuccessPayload> => {
  const query = gql`
    mutation UpdateUserMutation($userData: UserUpdateInput!) {
      updateUser(data: $userData) {
        success
      }
    }
  `;

  const authToken = getAuthToken(address);
  const requestHeaders = {
    Authorization: `Bearer ${authToken}`,
  };

  const res: { updateUser: SuccessPayload } = await graphql({ query, params: { userData }, requestHeaders });
  return res.updateUser;
};

export const useUpdateUser = (options?: UseMutationOptions<SuccessPayload, any, UpdateUserParams>) => {
  const { address: connectedAddress } = useAccount();
  const invalidateUserQuery = useInvalidateUser();
  return useMutation<SuccessPayload, any, UpdateUserParams>({
    mutationFn: ({ data }) => updateUser(connectedAddress!, data),
    onSuccess: () => {
      invalidateUserQuery(connectedAddress!);
    },
    ...options,
  });
};

/**
 * Update user profile mutation
 */
export const toggleProfileImageVisibility = async (address: Address): Promise<SuccessPayload> => {
  const query = gql`
    mutation ToggleProfileImageVisibility {
      toggleProfileImageVisibility {
        success
      }
    }
  `;

  const authToken = getAuthToken(address);
  const requestHeaders = {
    Authorization: `Bearer ${authToken}`,
  };

  const res: { toggleProfileImageVisibility: SuccessPayload } = await graphql({ query, requestHeaders });
  return res.toggleProfileImageVisibility;
};

export const useToggleProfileImageVisibility = (options?: UseMutationOptions<SuccessPayload, unknown, unknown>) => {
  const { address: connectedAddress } = useAccount();
  return useMutation<SuccessPayload, unknown, unknown>({
    mutationFn: () => toggleProfileImageVisibility(connectedAddress!),
    ...options,
  });
};

/**
 * Update User email
 */
export const updateUserEmail = async (address: Address, email: string | null): Promise<SuccessPayload> => {
  const query = gql`
    mutation UpdateUserEmailMutation($email: String) {
      updateUserEmail(email: $email) {
        success
      }
    }
  `;

  const authToken = getAuthToken(address);

  const requestHeaders = {
    Authorization: `Bearer ${authToken}`,
  };

  const res: { updateUserEmail: SuccessPayload } = await graphql({ query, params: { email }, requestHeaders });

  return res.updateUserEmail;
};

export const useEmailUpdate = (address: Address, email: string | null) => {
  const invalidate = useInvalidateUser();
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      try {
        const response = await updateUserEmail(address!, email);
        if (response.success) {
          invalidate(address!);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  return { mutate, isPending };
};

/**
 * confirm User email
 */
export const confirmUserEmail = async (address: Address, confirmationId: string): Promise<SuccessPayload> => {
  const query = gql`
    mutation ConfirmUserEmailMutation($confirmationId: String!) {
      confirmUserEmail(confirmationId: $confirmationId) {
        success
      }
    }
  `;

  const authToken = getAuthToken(address);

  const requestHeaders = {
    Authorization: `Bearer ${authToken}`,
  };

  const res: { confirmUserEmail: SuccessPayload } = await graphql({
    query,
    params: { confirmationId },
    requestHeaders,
  });

  return res.confirmUserEmail;
};
