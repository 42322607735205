import { useTranslation } from "next-i18next";
import { usePublicClient } from "wagmi";
import { Address } from "viem";
import { Currency, INDEXER_REFETCH_DELAY_MS } from "@looksrare/config";
import { AutoTransactionStepRow, TransactionSetter } from "@looksrare/uikit";
import {
  NoPublicClientError,
  formatToDisplayDecimals,
  isAddressEqual,
  sleep,
  useHandleModalStep,
} from "@looksrare/utils";
import { RakebackClaimDetails, Rakebacks } from "@looksrare/yolo-games-gql-typegen";
import { useClaimRakeback } from "@/hooks/rakeback";

interface ClaimRakebackTransactionRowProps {
  claimDetails: RakebackClaimDetails;
  userRakebacks: Rakebacks;
  claimIndex: number;
  isActive: boolean;
  onSuccess?: () => void;
}

export const ClaimRakebackTransactionRow = ({
  claimDetails,
  userRakebacks,
  claimIndex,
  isActive,
  onSuccess,
}: ClaimRakebackTransactionRowProps) => {
  const { t } = useTranslation();
  const claimCurrency = claimDetails.currency;

  const publicClient = usePublicClient();
  const claimRakeback = useClaimRakeback(claimCurrency.symbol as Currency);

  const totalClaimed = userRakebacks.totalClaimed.find((claim) =>
    isAddressEqual(claim.currency.address, claimCurrency.address)
  );

  const cumulativeEthAmountWei = BigInt(claimDetails.cumulativeAmountWei);
  const totalClaimedWei = BigInt(totalClaimed?.amountWei ?? 0n);
  const currentClaimAmountWei = cumulativeEthAmountWei - totalClaimedWei;

  const hasExpired = !claimDetails?.endsAt || new Date(claimDetails.endsAt) < new Date();

  const useHandleTransaction = (setTransaction: TransactionSetter) => {
    return useHandleModalStep({
      onSubmit: async () => {
        if (!publicClient) {
          throw new NoPublicClientError();
        }

        if (!claimDetails) {
          throw new Error("Claim details are missing. Please try again later");
        }

        if (hasExpired) {
          throw new Error("Claim period has ended. Please try again later");
        }

        // Contract does want the cumulative amount, not the current amount we're claiming
        const hash = await claimRakeback(cumulativeEthAmountWei, claimDetails.proof as Address[]);
        setTransaction(hash);
        const receipt = await publicClient.waitForTransactionReceipt({ hash });

        if (receipt.status === "success") {
          // Wait for the transaction to be indexed
          await sleep(INDEXER_REFETCH_DELAY_MS);

          setTransaction(undefined);
        } else {
          throw new Error(
            `Claiming ${claimCurrency.symbol} rackback failed. Transaction hash ${receipt.transactionHash}`
          );
        }

        onSuccess?.();
      },
    });
  };

  if (hasExpired) {
    return null;
  }

  return (
    <AutoTransactionStepRow
      useHandleTransaction={useHandleTransaction}
      ctaText={`#${claimIndex + 1} ${t("Claim {{amount}} {{currency}}", {
        amount: formatToDisplayDecimals({ amountWei: currentClaimAmountWei, currency: claimCurrency, size: "large" }),
        currency: claimCurrency.symbol,
      })}`}
      isStepActive={isActive}
    />
  );
};
