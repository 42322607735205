import { useCallback } from "react";
import { Address } from "viem";
import { usePublicClient, useWalletClient } from "wagmi";
import { BigIntish, NoPublicClientError, RQueryOptions, getAuthToken, graphql } from "@looksrare/utils";
import { gql } from "graphql-request";
import { useQuery } from "@tanstack/react-query";
import { getDefaultChain } from "@/utils";
import { gameAddresses } from "@/config";
import { boostAbi } from "@/config/abi/boostAbi";
import { userKeys } from "@/queries/user";

export interface UserBoostProgress {
  boostAllocationWei: string;
  boostSpentWei: string;
  progressPercentage: number;
}

/**
 * Query User Boost Spent Progress
 */
export const getUserBoostProgress = async (address: Address): Promise<UserBoostProgress | null> => {
  const query = gql`
    query UserBoostProgress($address: Address!) {
      user(address: $address) {
        yoloBoostMetrics {
          boostAllocationWei
          boostSpentWei
          progressPercentage
        }
      }
    }
  `;

  const authToken = getAuthToken(address);

  const requestHeaders = {
    Authorization: `Bearer ${authToken}`,
  };

  const res: { user: { yoloBoostMetrics?: UserBoostProgress } } = await graphql({
    query,
    params: { address },
    requestHeaders,
  });

  return res.user.yoloBoostMetrics ?? null;
};

export const useUserBoostProgress = (address: Address, queryOptions?: RQueryOptions<UserBoostProgress | null>) => {
  return useQuery({
    queryKey: userKeys.boostProgress(address),
    queryFn: async () => getUserBoostProgress(address),
    refetchOnWindowFocus: false,
    staleTime: 10_000,
    ...queryOptions,
  });
};

interface ClaimBoostParams {
  address: Address;
  proof: string[];
  amount: BigIntish;
}

export const useClaimBoost = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();

  return useCallback(
    async ({ address, proof, amount }: ClaimBoostParams) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      const contractInfo = {
        address: addresses.BOOST,
        abi: boostAbi,
      };

      const { request } = await publicClient.simulateContract({
        ...contractInfo,
        functionName: "claim",
        account: address,
        args: [BigInt(amount), proof],
      });
      const hash = await walletClient.writeContract(request);
      return hash;
    },
    [publicClient, walletClient]
  );
};

export const useBurnBoost = () => {
  const { data: walletClient } = useWalletClient();
  const publicClient = usePublicClient();
  return useCallback(
    async (amount: bigint) => {
      if (!walletClient) {
        throw Error("No wallet client found");
      }
      if (!publicClient) {
        throw new NoPublicClientError();
      }
      const chainId = getDefaultChain();
      const addresses = gameAddresses[chainId];

      const contractInfo = {
        address: addresses.BOOST,
        abi: boostAbi,
      };

      const [account] = await walletClient.getAddresses();
      const { request } = await publicClient.simulateContract({
        ...contractInfo,
        functionName: "burn",
        args: [amount],
        account,
      });
      return walletClient.writeContract(request);
    },
    [walletClient, publicClient]
  );
};
