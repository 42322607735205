import { HStack, Stack, StackProps } from "@chakra-ui/react";
import { Text, currencyTokenIconMap } from "@looksrare/uikit";
import { formatToDisplayDecimals, formatUsd, fromDecimals, useCoinPrices } from "@looksrare/utils";
import { useTranslation } from "next-i18next";
import { useAccount } from "wagmi";
import { useUserRakebackData } from "@/hooks/rakeback";

export const RakebackStats = (props: StackProps) => {
  const { t } = useTranslation();

  const { address } = useAccount();
  const { data: userRakebackData } = useUserRakebackData(address!, {
    enabled: !!address,
  });

  const { data: coinPrices } = useCoinPrices();
  const ethUsdPrice = coinPrices?.eth.price ?? 0;

  const totalClaimedFloat =
    userRakebackData?.rakebacks.totalClaimed.reduce(
      (acc, current) => acc + Number(fromDecimals(current.amountEthWei)),
      0
    ) ?? 0;
  const totalClaimedDollar = totalClaimedFloat * ethUsdPrice;

  return (
    <Stack
      spacing={4}
      direction={{ base: "column", sm: "row" }}
      alignItems={{ base: "start", sm: "center" }}
      px={6}
      py={4}
      backgroundColor="ui-01"
      borderRadius="dialog"
      borderWidth="1px"
      borderColor="border-01"
      {...props}
    >
      <Stack spacing={0} width="100%">
        <Text textStyle="display-body" bold>
          {t("Total Claimed")}
        </Text>

        <Text textStyle="detail" color="text-03">
          {t("Appx. {{amount}}", { amount: formatUsd(totalClaimedDollar) })}
        </Text>
      </Stack>

      <HStack width="100%">
        {userRakebackData?.rakebacks.totalClaimed.map(({ amountWei, amountEthWei, currency }) => {
          const TokenIcon = currencyTokenIconMap[currency.symbol];
          const amountDollar = Number(fromDecimals(amountEthWei)) * ethUsdPrice;

          return (
            <Stack key={currency.address} spacing={0} flex={1}>
              <HStack spacing={2}>
                <TokenIcon boxSize={5} />

                <Text textStyle="display-body" bold>
                  {formatToDisplayDecimals({ amountWei, currency, size: "large" })}
                </Text>
              </HStack>

              <Text textStyle="detail" color="text-03">
                {formatUsd(amountDollar)}
              </Text>
            </Stack>
          );
        })}
      </HStack>
    </Stack>
  );
};
