import { useRef } from "react";
import { Address } from "viem";
import { IconButton, InputGroup, InputRightElement, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { CloseIcon, Text } from "@looksrare/uikit";
import { useGetFormattedErrorAndTitle, useIsBlastApp, useSignInHandler } from "@looksrare/utils";
import { Button, ButtonProps, Input, Textarea } from "@looksrare/uikit";
import { FormLabel, InputError, InputMessageGroup } from "@/components/Form";
import { useInvalidateUser, useUpdateUser } from "@/hooks/user";
import { useToast } from "@/hooks/useToast";
import { SignInModal } from "@/components/Modals/SignInModal";
import { User } from "@/types/user";
import { useUser } from "@/queries/user";
import { DiscordConnectionButton, TwitterConnectionButton } from "./SocialConnectionButtons";

interface UserProfileFormProps {
  address: Address;
  user: User;
}

interface Inputs {
  biography: string;
}

const DESCRIPTION_MAX_LENGTH = 300;

export const UserProfileForm = ({ address, user }: UserProfileFormProps) => {
  const { toast } = useToast();
  const { t } = useTranslation();
  const isBlastApp = useIsBlastApp();
  const formRef = useRef<HTMLFormElement>(null);
  const getFormattedErrorAndTitle = useGetFormattedErrorAndTitle();
  const invalidateUserQuery = useInvalidateUser();
  const { refetch: refetchUser } = useUser(address, { enabled: !!address });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
    watch,
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      biography: user?.biography,
    },
  });

  const updateUserMutation = useUpdateUser({
    onSuccess: () => {
      toast({
        title: t("Successful Profile Update"),
        description: user?.name
          ? t("Your profile {{name}} has been updated", { name: user.name })
          : t("Your profile has been created"),
        status: "success",
        dataIdSuffix: "update-user",
      });
      invalidateUserQuery(address);
    },
    onError: (error) => {
      const { title, description } = getFormattedErrorAndTitle(error);
      toast({ status: "error", title, description, dataIdSuffix: "update-user" });
    },
  });

  const onSubmit: SubmitHandler<Inputs> = ({ biography }) => {
    return updateUserMutation.mutate({
      address,
      data: {
        biography: biography || null,
      },
    });
  };

  const { signInHandler, hasValidToken, isSigning, txStatus } = useSignInHandler({
    onAuthSuccess: () => {
      // formRef.current.submit() causes the page to refresh
      formRef.current?.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
    },
  });
  const disclosure = useDisclosure();
  // If the user does not have a valid JWT the button becomes a normal button, otherwise it is a submit button
  const formButtonProps: ButtonProps = hasValidToken ? { type: "submit" } : { onClick: signInHandler };

  const handleRemoveUsernameClick = () => {
    return updateUserMutation.mutate(
      { address, data: { name: null } },
      {
        onSuccess: () => refetchUser(),
      }
    );
  };

  const handleDisconnectSocial = async () => {
    await refetchUser();
    toast({
      title: t("Confirmed"),
      description: t("Your social account was removed successfully"),
      status: "success",
    });
  };

  return (
    <>
      <form method="post" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <fieldset disabled={isSubmitting}>
          <FormLabel>{t("Username")}</FormLabel>
          <InputGroup>
            <Input
              bg="field-01"
              _disabled={{ bg: "field-01", cursor: "not-allowed" }}
              placeholder={user.name ?? ""}
              color="text-03"
              disabled={true}
              pr={12}
              maxLength={20}
            />
            {user.name && (
              <InputRightElement width="max-content" height="100%">
                <IconButton
                  type="submit"
                  onClick={handleRemoveUsernameClick}
                  borderRadius="button"
                  borderLeftRadius={0}
                  size="sm"
                  colorScheme="gray"
                  aria-label={t("Remove username")}
                >
                  <CloseIcon />
                </IconButton>
              </InputRightElement>
            )}
          </InputGroup>
          {!user.name && (
            <InputMessageGroup>
              <Text textStyle="detail" color="text-03">
                {t("Connect X account to set")}
              </Text>
            </InputMessageGroup>
          )}

          <FormLabel mt={8}>{t("Bio")}</FormLabel>
          <Textarea
            placeholder="Tell us about yourself!"
            boxShadow="none"
            minH={28}
            {...register("biography", {
              maxLength: {
                value: DESCRIPTION_MAX_LENGTH,
                message: t("Max limit of {{maxLength}} characters", { maxLength: DESCRIPTION_MAX_LENGTH }),
              },
            })}
            isInvalid={!!errors.biography}
            bg="field-01"
            color="white"
          />
          <InputMessageGroup mb={8}>
            {errors.biography ? (
              <InputError textStyle="detail">{errors.biography && errors.biography.message}</InputError>
            ) : (
              <Text textStyle="detail" color="text-03">
                {watch("biography")?.length ?? 0} / {DESCRIPTION_MAX_LENGTH} {t("Max Characters")}
              </Text>
            )}
          </InputMessageGroup>
          {!isBlastApp && (
            <>
              <FormLabel>{t("Social")}</FormLabel>
              <InputGroup mb={2}>
                <TwitterConnectionButton
                  onConnect={refetchUser}
                  onDisconnect={handleDisconnectSocial}
                  handle={user.twitterUsername}
                />
              </InputGroup>
              <InputGroup mb={2}>
                <DiscordConnectionButton
                  onConnect={refetchUser}
                  onDisconnect={handleDisconnectSocial}
                  handle={user.discordUsername}
                />
              </InputGroup>
            </>
          )}
        </fieldset>
        <Button
          mt={8}
          isDisabled={!isValid || user?.biography === watch("biography")}
          isLoading={isSubmitting}
          {...formButtonProps}
        >
          {t("Save Changes")}
        </Button>
      </form>
      <SignInModal isOpen={isSigning} onClose={disclosure.onClose} txStatus={txStatus} signInHandler={signInHandler} />
    </>
  );
};
